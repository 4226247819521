import { reactive, watch } from "vue";
import { App } from "vue";
import { colorRgb } from "@/utils";

type MainType = {
  "main-color": string;
  "sub-color": string;
  theme: string;
  "color-orange": string;
  "color-blue": string;
  "color-red": string;
  "color-yellow": string;
  "color-lightGreen": string;
  "main-color1": string;
  "main-color2": string;
  "main-color3": string;
  "main-color4": string;
  "main-color5": string;
  "main-color6": string;
  "main-color7": string;
  "main-color8": string;
  "main-color9": string;
  "main-color10": string;
  "main-color-icon": string;
  "header-h": string;
  "sider-w": number;
  [propName: string]: string | number;
};

const variate = reactive<MainType>({
  // 颜色
  "main-color": "#4343F0",
  "sub-color": "#9991eb",
  theme: "#000",
  "color-orange": "#F47A23",
  "color-blue": "#1B83ED",
  "color-red": "#E61300",
  "color-yellow": "#FFB100",
  "color-lightGreen": "#39e597",
  "main-color1": "#f0f2ff",
  "main-color2": "#ebedff",
  "main-color3": "#c2c8ff",
  "main-color4": "#99a0ff",
  "main-color5": "#6f74fc",
  "main-color6": "#4343f0",
  "main-color7": "#342ec9",
  "main-color8": "#261da3",
  "main-color9": "#1b107d",
  "main-color10": "#150a57",
  "main-color-icon": "#6D7AFE",
  "header-h": "50px",
  "sider-w": 180,
});

for (const key in variate) {
  watch(
    () => variate[key],
    () => {
      if (key === "theme") {
        const list = ["0.85", "0.65", "0.45", "0.25", "0.15", "0.04"];
        list.forEach((item, index) => {
          document.documentElement.style.setProperty(
            `--color${index + 1}`,
            `rgba( ${colorRgb(variate[key])}, ${item} )`
          );
        });
      } else {
        document.documentElement.style.setProperty(`--${key}`, String(variate[key]));
      }
    },
    {
      immediate: true,
    }
  );
}

const setVariate = function (app: App<Element>): void {
  app.config.globalProperties.$variate = variate;
};

export { variate, setVariate, MainType };
