import { ref, nextTick, watch } from "vue";

import { getPlugins, getCards, getPluginConfig } from "./config";

import Engine, { EngineInterface, $ } from "@aomao/engine";

import { message, Modal } from "ant-design-vue";

import lodash from "lodash";

import { homeState } from "@/store";

import { isReadonly } from "@/views/doc/index";

import { currentDocInfo } from "@/views/doc/index";

import { DocType } from "@/types";

// 编辑器引擎
const engine = ref<EngineInterface | null>(null);

// 编辑器容器
const container = ref<HTMLElement | null>(null);

const isSetContent = ref(false);

/**
 *
 * @param str html 文档内容
 * @description 将传入的内容进行填充 聚焦 并将改变初始化
 */
const setContent = (str: string): void => {
  isSetContent.value = true;

  const onEnd = lodash.debounce(
    () => {
      if (currentDocInfo.value?.file_type === DocType.word) {
        engine.value?.focus(false);
      }
    },
    300,
    {
      leading: false,
      trailing: true,
    }
  );
  engine.value?.setHtml(str, onEnd);
};

/**
 * @description 初始化 编辑器对象
 */
const initEditor = (): void => {
  engine.value?.destroy();
  engine.value = null;
  // commentRef = comment;
  //实例化引擎

  nextTick(() => {
    if (!container.value || !homeState.mediaServer) return;

    const engineInstance = new Engine(container.value, {
      readonly: isReadonly.value,
      // 启用的插件
      plugins: getPlugins(),
      // 启用的卡片
      cards: getCards(),
      // 所有的卡片配置
      config: {
        ...getPluginConfig(),
        // "mark-range": getConfig(engine as any, comment),
      },
    });

    const scrollNode = document.querySelector(".editor-container") as HTMLElement;

    scrollNode && engineInstance.setScrollNode(scrollNode);

    // 消息相关处理
    {
      // 设置显示成功消息UI，默认使用 console.log
      engineInstance.messageSuccess = (msg: string) => {
        message.success(msg);
      };

      // 设置显示错误消息UI，默认使用 console.error
      engineInstance.messageError = (error: string) => {
        message.error(error);
      };

      // 设置显示确认消息UI，默认无
      engineInstance.messageConfirm = (msg: string) => {
        return new Promise<boolean>((resolve, reject) => {
          Modal.confirm({
            content: msg,
            onOk: () => resolve(true),
            onCancel: () => reject(),
          });
        });
      };
    }

    // 时间相关
    {
      // 监听编辑器值改变事件
      engineInstance.on("change", () => {
        if (isSetContent.value) {
          isSetContent.value = false;
        } else {
          // isChange.value = true;
        }
      });

      //卡片最大化时设置编辑页面样式
      engineInstance.on("card:maximize", () => {
        $(".editor-toolbar").css("z-index", "9999").css("top", "55px");
      });
      engineInstance.on("card:minimize", () => {
        $(".editor-toolbar").css("z-index", "").css("top", "");
      });
    }

    // 注入
    engine.value = engineInstance;
  });
};

// 初始编辑器
watch(
  () => homeState.mediaServer,
  (val) => {
    if (val) {
      initEditor();
    }
  }
);

export { engine, initEditor, container, setContent };
