//语言
import { createI18n } from "vue-i18n"; //引入vue-i18n组件

import { ref } from "vue";

import api_en from "./langs/api_en";
import api_zh from "./langs/api_zh";

const messages = {
  en: Object.assign(api_en),
  zh: Object.assign(api_zh),
};

// import { 引入的组件 export 出来的 变量} from 'vue-i18n'
//注册i8n实例并引入语言文件
const i18n = createI18n({
  locale: "zh", //默认显示的语言
  messages,
});

const t = ref();

const getMsg = (str: string | number, defaultMsg: string | number = ""): string => {
  if (!t.value) return "-";

  let returnValue = t.value(String(str));

  if (returnValue === String(str) && defaultMsg) {
    returnValue = t.value(String(defaultMsg));
  }

  return returnValue;
};

export { t, getMsg };

export default i18n;
