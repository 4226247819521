import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import Antd from "ant-design-vue";

// 全局组件
import setCom from "./components/Common";
import setDirectives from "./directives/index";
import { setVariate } from "./styles";
import "ant-design-vue/dist/antd.css";
import "./assets/js/iconfont.js";
import "./styles/index.scss";
import "./styles/index.css";
import { setMethod } from "./utils";
import "./permission";

import "./assets/icons/index";

import "@/hooks/channel";

import VueI18n from "./i18n";

const app = createApp(App);

setCom(app);
setVariate(app);

setMethod(app);

setDirectives(app);
// getApp(app);

app.use(Antd);
app.use(VueI18n);
// app.use(ElementPlus);
app.use(router);
app.mount("#app");
