import { ref, nextTick } from "vue";

import { DocType, DocInfo } from "@/types";

import { myEmitter, setExcalidrawVal } from "@/components/excalidraw/index";

import { setContent, engine } from "@/components/aomao-editor/index";

import { message } from "ant-design-vue";

import mediaType, { AcceptType } from "@/utils/fileType";

import { getMediaUrl, uploadFile } from "@/utils";

const EventEmitter = require("events");

class MyEmitter extends EventEmitter {}

export const docEmitter = new MyEmitter();

// 表格编辑的配置
const yesConfig = {
  plugins: ["chart"],
  uploadImage: (file: File) => {
    return new Promise((resolve, reject) => {
      if (mediaType[AcceptType.image].find((el) => file.type.indexOf(el) > -1)) {
        uploadFile(file)
          .then((res) => {
            const url = getMediaUrl(res[0]).url;
            url ? resolve(url) : reject();
          })
          .catch(() => {
            reject();
          });
      } else {
        message.warning("不支持的文件格式哦");
        reject();
      }
    });
  },
  ctrlSBack: () => {
    docEmitter.emit("ctrlS");
  },
};
// 表格只读的配置
const noConfig = {
  disableCtrlV: true,
  allowCopy: false, // 是否允许拷贝
  showtoolbar: false, // 是否显示工具栏
  showsheetbar: false, // 是否显示底部sheet页按钮
  showstatisticBar: false, // 是否显示底部计数栏
  sheetBottomConfig: false, // sheet页下方的添加行按钮和回到顶部按钮配置
  allowEdit: false, // 是否允许前台编辑
  enableAddRow: false, // 允许增加行
  enableAddCol: false, // 允许增加列
  userInfo: false, // 右上角的用户信息展示样式
  showRowBar: false, // 是否显示行号区域
  showColumnBar: false, // 是否显示列号区域
  sheetFormulaBar: false, // 是否显示公式栏
  enableAddBackTop: false, //返回头部按钮
  rowHeaderWidth: 0, //纵坐标
  columnHeaderHeight: 0, //横坐标
  showstatisticBarConfig: {
    count: false,
    view: false,
    zoom: false,
  },
  showsheetbarConfig: {
    add: false, //新增sheet
    menu: false, //sheet管理菜单
    sheet: true, //sheet页显示
  },
  sheetRightClickConfig: {
    delete: false, // 删除
    copy: false, // 复制
    rename: false, //重命名
    color: false, //更改颜色
    hide: false, //隐藏，取消隐藏
    move: false, //向左移，向右移
  },
  forceCalculation: true, //强制计算公式
};

// 当前文档的类型
const currentDocType = ref<DocType>(DocType.init);
// 是否只读
const isReadonly = ref(false);
// 当前文档信息
const currentDocInfo = ref<DocInfo | null>(null);
// 是否改变
const isChange = ref(false);

// 重置编辑器数据
const reset = (): void => {
  setContent("");

  engine.value && (engine.value.readonly = true);

  engine.value?.history.reset();

  luckysheet?.destroy();

  myEmitter.emit("parentOperate", {
    type: "reset",
  });

  myEmitter.emit("parentOperate", {
    type: "setDisable",
    data: true,
  });
};

// 将值插入编辑器中
const set = (
  doc: { content?: string; file_type?: DocType } & Global.UnknownObj
): void => {
  reset();
  nextTick(() => {
    currentDocInfo.value = doc;
    // 数据载入
    {
      if (doc.file_type === DocType.word) {
        setContent(doc.content || "");
        // 只读模式切换
        engine.value && (engine.value.readonly = isReadonly.value);
      } else if (doc.file_type === DocType.excel) {
        const options: Global.UnknownObj = {
          container: "luckysheet",
          lang: "zh",
          showinfobar: false,
        };

        if (isReadonly.value) {
          Object.assign(options, noConfig);
        } else {
          Object.assign(options, yesConfig);
        }

        if (doc.content) {
          try {
            options.data = JSON.parse(doc.content);
          } catch (error) {
            console.log(error);
          }
        } else {
          options.data = [
            {
              name: "Sheet1",
            },
          ];
        }
        luckysheet?.create(options);
      } else if (doc.file_type === DocType.draw) {
        setExcalidrawVal(doc.content);
      }
    }
    // 类型赋值
    currentDocType.value = doc.file_type || DocType.init;
  });
};

// 从编辑器中获取数据
const getVal = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!currentDocInfo.value) {
      reject("无数据");
      return;
    }

    const { file_type } = currentDocInfo.value;

    if (file_type === DocType.word) {
      resolve(engine.value?.getHtml() || "");
    } else if (file_type === DocType.excel) {
      const { data: dataCopy } = luckysheet?.toJson();

      resolve(JSON.stringify(dataCopy) || "");
    } else if (file_type === DocType.draw) {
      let judge = false;

      myEmitter.emit("parentOperate", {
        type: "save",
        cb: (val: string) => {
          judge = true;
          resolve(val);
        },
      });
      setTimeout(() => {
        !judge && reject("获取超时");
      }, 1000);
    } else {
      reject("类型不支持");
    }
  });
};

export { currentDocType, isReadonly, reset, set, currentDocInfo, getVal, isChange };
