import { nextTick, ref, watch } from "vue";

import { isReadonly } from "@/views/doc";

const EventEmitter = require("events");

class MyEmitter extends EventEmitter {}

const myEmitter = new MyEmitter();

const isReady = ref(false);

const cacheData = ref("");

watch(isReady, () => {
  nextTick(() => {
    setExcalidrawVal(cacheData.value);
  });
});

const setExcalidrawVal = (val?: string): void => {
  if (isReady.value) {
    if (val) {
      try {
        myEmitter.emit("parentOperate", {
          type: "set",
          data: JSON.parse(val),
        });
      } catch (error) {
        console.log(error);
      }
    }
    myEmitter.emit("parentOperate", {
      type: "setDisable",
      data: isReadonly.value,
    });
  } else {
    cacheData.value = val || "";
  }
};

export { myEmitter, isReady, setExcalidrawVal };
