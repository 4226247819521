const en = {
  //  framework
  "400": "网络异常，请检查网络连接",
  "404": "未找到资源。",
  "-400001": "参数格式错误。",
  "-400002": "字段格式必须包含点符号。",
  "-400003": "模块不存在。",
  "-400004": "非法操作。",
  "-411111": "数据记录已经存在。",
  "-411112": "没有数据被更新。",
  "-4001001": "不支持调度类型。",
  "-4001002": "方法参数不存在。",
  "-4001003": "缓存数据push类型必须为数组。",
  "-4001004": "类不存在。",
  "-4001005": "无法创建目录。",
  "-4001006": "无法移动文件。",
  "-4001007": "非空PSR-4前缀必须以命名空间分隔符结尾。",
  "-4001008": "变量类型错误。",
  "-4001009": "未定义类。",
  "-4001010": "数据结构错误需要模块。",
  "-4001011": "文件不存在。",
  "-4001012": "json序列化失败。",
  "-4001013": "方法不存在。",
  "-4001014": "无法加载数据库驱动。",
  "-4001015": "找不到数据库配置。",
  "-4001016": "非法数据对象。",
  "-4001017": "属性不存在。",
  "-4001018": "存储写入错误。",
  "-4001019": "数据合法性验证失败",
  "-4001020":
    "密码长度不小于8位，必须包含数字，小写字母，大写字母和特殊字符，其中任意三种",
  // im
  "-20210001": "添加钉钉配置失败。",
  "-20210002": "客户等级参数错误。",
  "-20210003": "创建自定义字段失败。",
  "-20210004": "创建自定义字段请求参数错误。",
  "-20210005": "模块不存在。",
  "-20210006": "修改字段配置失败。",
  "-20210007": "配置不存在。",
  "-20210008": "当前用户不是激活租户。",
  "-20210009": "找不到匹配记录。",
  "-20210010": "找不到用户信息。",
  "-20210011": "只有管理员才能删除用户。",
  "-20210012": "你不是管理员。",
  "-20210013": "要传输的用户不存在。",
  "-20210014": "你不属于这个聊天群。",
  "-20210114": "你已不在本群",
  "-20210015": "管理员不能直接退出聊天群，需要先转移管理员。",
  "-20210016": "类不存在。",
  "-20210017": "模块不存在。",
  "-20210018": "创建失败。",
  "-20210019": "修改失败。",
  "-20210020": "删除失败。",
  "-20210021": "删除项不存在。",
  "-20210022": "请求参数缺少主键ID数据。",
  "-20210023": "没有匹配数据。",
  "-20210024": "非法操作。",
  "-20210025": "租户不存在。",
  "-20210026": "创建部门失败。",
  "-20210027": "法大大接口异常。",
  "-20210028": "缺少媒体服务器配置数据。",
  "-20210029": "缺少租户信息。",
  "-20210030": "缺少租户身份验证信息。",
  "-20210031": "租户身份验证未通过审核。",
  "-20210032": "缺少用户信息。",
  "-20210033": "缺少用户身份验证信息。",
  "-20210034": "授权操作失败。",
  "-20210035": "缺少聊天数据。",
  "-20210036": "添加新消息失败。",
  "-20210037": "消息模板不标准。",
  "-20210038": "消息模板不存在。",
  "-20210039": "非法请求。",
  "-20210040": "添加客户端失败。",
  "-20210041": "无法修改OAuth客户端。",
  "-20210042": "删除客户端失败。",
  "-20210043": "未删除任何数据。",
  "-20210044": "数据不存在。",
  "-20210045": "无效的短信验证码。",
  "-20210046": "系统配置更新失败。",
  "-20210047": "新增短信失败。",
  "-20210048": "短信腾讯云网关错误。",
  "-20210049": "短信其他网关错误。",
  "-20210050": "手机号码已注册。",
  "-20210051": "手机号码格式错误。",
  "-20210052": "上一条消息仍然有效。",
  "-20210053": "手机号码未注册。",
  "-20210054": "未找到租户授权记录。",
  "-20210055": "用户已存在。",
  "-20210056": "用户不存在。",
  "-20210057": "更新部门信息失败。",
  "-20210058": "找不到指定的租户。",
  "-20210059": "同一时间只能申请一个团队。",
  "-20210060": "申请加入团队失败。",
  "-20210061": "同意用户加入团队失败。",
  "-20210062": "用户应用程序信息不存在。",
  "-20210063": "拒绝用户加入团队失败。",
  "-20210064": "租户还未进行企业认证。",
  "-20210065": "银行转账充值失败。",
  "-20210066": "添加用户余额失败。",
  "-20210067": "扣除用户余额失败。",
  "-20210068": "确认充值失败。",
  "-20210069": "增加租户余额失败。",
  "-20210070": "没有权限进行此操作。",
  "-20210071": "生成登录令牌失败。",
  "-20210072": "短信验证码错误。",
  "-20210073": "用户不存在或密码错误。",
  "-20210074": "二维码未授权。",
  "-20210075": "二维码已过期。",
  "-20210076": "用户不存在或密码错误。",
  "-20210077": "写入二维码授权失败。",
  "-20210078": "同一个刷新令牌被锁定一分钟。",
  "-20210079": "刷新令牌无效。",
  "-20210080": "刷新令牌已过期。",
  "-20210081": "无法查询用户信息。",
  "-20210082": "创建用户失败。",
  "-20210083": "修改用户失败。",
  "-20210084": "删除用户失败。",
  "-20210085": "令牌已过期。",
  "-20210086": "Header参数错误。",
  "-20210087": "用户信息参数错误。",
  "-20210088": "模块参数不存在。",
  "-20210089": "请求参数错误。",
  "-20210090": "频道不存在。",
  "-20210091": "无法与自己聊天。",
  "-20210092": "缺少接收者。",
  "-20210093": "ONE2ONE类型只支持与一个用户聊天。",
  "-20210094": "找不到相关用户。",
  "-20210095": "缺少身份验证事务数据。",
  "-20210096": "缺少服务请求URL字段。",
  "-20210097": "添加媒体失败。",
  "-20210098": "缺少OAuth客户端数据。",
  "-20210099": "添加OAuth编码失败。",
  "-20210100": "授权码错误。",
  "-20210101": "授权码与当前应用不匹配。",
  "-20210102": "未找到属性。",
  "-20210103": "解密失败。",
  "-20210104": "自动加入部门聊天失败。",
  "-20210105": "公司账户余额太低。",
  "-20210106": "账户余额应该必须大于零。",
  "-20210107": "添加OAuth令牌失败。",
  "-20210108": "该供应商已经添加",
  //  oauth
  "-20310001": "缺少服务请求url字段。",
  "-20310002": "用户不存在或密码错误。",
  "-20310003": "OAuth设备码保存失败。",
  "-20310004": "二维码未授权。",
  "-20310005": "二维码已过期。",
  "-20310006": "客户端不存在。",
  "-20310007": "无效短信验证码。",
  "-20310008": "机器人访问凭证不存在。",
  "-20310009": "请求参数错误。",
  "-20310010": "删除资源失败。",
  "-20310011": "应用程序编码资源不存在。",
  "-20310012": "创建权限规则失败。",
  "-20310013": "发送短信失败。",
  "-20310014": "短信腾讯云网关错误。",
  "-20310015": "短信其他网关错误。",
  "-20310016": "手机号码已注册。",
  "-20310017": "手机号码格式错误。",
  "-20310018": "上一条消息仍然有效。",
  "-20310019": "手机号码未注册。",
  "-20310021": "创建用户帐户失败。",
  "-20310022": "缺少制片人角色。",
  "-20310023": "登录已过期",
  "-20310024": "登录已过期",
  // saas
  "-20110002": "未生成工序任务。",
  "-20110003": "工序类别未绑定实体。",
  "-20110004": "没有找到实体数据。",
  "-20110005": "批量创建属性值请求参数错误。",
  "-20110006": "合同模板文件不存在。",
  "-20110007": "合同模板文件必须为PDF格式。",
  "-20110008": "合同模板文件不支持更新。",
  "-20110009": "Crontab定时任务令牌不存在。",
  "-20110010": "Crontab定时任务令牌无效。",
  "-20110011": "创建实体请求参数错误。",
  "-20110012": "上传文件不存在。",
  "-20110013": "上传文件失败。",
  "-20110014": "上传文件失败。",
  "-20110015": "仅允许上传后缀为 .csv、.xls、.xlsx 的Excel文件。",
  "-20110016": "创建订单请求参数错误。",
  "-20110017": "创建订单任务清单请求参数错误。",
  "-20110018": "更新订单任务清单请求参数错误。",
  "-20110019": "批量保存计划请求参数错误。",
  "-20110020": "发起审批请求参数错误。",
  "-20110021": "设置实体任务供应商请求参数错误。",
  "-20110022": "设置工序任务执行人请求参数错误。",
  "-20110023": "设置任务开始结束时间请求参数错误。",
  "-20110024": "创建媒体请求参数错误。",
  "-20110025": "创建自定义字段失败。",
  "-20110026": "创建自定义字段请求参数错误。",
  "-20110027": "当前模块不存在。",
  "-20110028": "当前时间范围不存在或者开始时间和截止时间范围不合理。",
  "-20110029": "创建属性失败。",
  "-20110030": "当前模块不存在。",
  "-20110031": "创建失败。",
  "-20110032": "修改失败。",
  "-20110033": "删除失败。",
  "-20110034": "指定删除项不存在。",
  "-20110035": "请求参数缺少主键ID数据 。",
  "-20110036": "没有匹配数据 。",
  "-20110037": "添加标签关联失败。",
  "-20110038": "删除标签关联失败。",
  "-20110039": "合同模板不存在。",
  "-20110040": "租户还未进行企业认证。",
  "-20110041": "租户CA证书未激活。",
  "-20110042": "租户还未进行企业认证。",
  "-20110043": "合同不存在。",
  "-20110044": "缺少合同模板数据。",
  "-20110045": "你没有操作这份合同权限。",
  "-20110046": "合同不存在。",
  "-20110047": "没有权限进行此操作。",
  "-20110048": "找不到匹配合同模板。",
  "-20110049": "已执行任务状态检查。",
  "-20110050": "创建磁盘失败。",
  "-20110051": "更新磁盘失败。",
  "-20110052": "删除磁盘失败。",
  "-20110053": "关联文档失败。",
  "-20110054": "没有数据需要生成。",
  "-20110055": "实体数据未绑定到工序流。",
  "-20110056": "工序流不存在。",
  "-20110057": "通过概念生成资产和关卡失败。",
  "-20110058": "同一序列下，镜头的制作方法和布料的计算方式不统一。",
  "-20110059": "镜头样式字段为必填项。",
  "-20110060": "通过分镜数据生成镜头失败。",
  "-20110061": "用户不存在。",
  "-20110062": "Excel文件名不能为空。",
  "-20110063": "Excel导出数据不能超过200行。",
  "-20110064": "Excel标题不能为空值。",
  "-20110065": "不能导入空Excel数据文件。",
  "-20110066": "Excel数据不能为空值。",
  "-20110067": "法大大接口异常。",
  "-20110068": "错误的用户信息参数。",
  "-20110069": "保存过滤条件失败。",
  "-20110070": "删除过滤条件失败。",
  "-20110071": "Excel文件不存在。",
  "-20110072": "Excel同一个单元格不能存在多张图片。",
  "-20110073": "Excel图片单元格不能有数据。",
  "-20110074": "Excel标题映射单元格字段必须有数据。",
  "-20110075": "模块缺少中间关联数据。",
  "-20110076": "依赖的模块数据不存在。",
  "-20110077": "必须包含主表数据。",
  "-20110078": "添加媒体失败。",
  "-20110079": "修改媒体失败。",
  "-20110080": "清除媒体失败。",
  "-20110081": "添加媒体服务配置失败。",
  "-20110082": "修改媒体服务配置失败。",
  "-20110083": "删除媒体服务配置失败。",
  "-20110084": "没有可用的媒体服务器。",
  "-20110085": "指定的媒体服务器不可用。",
  "-20110086": "指定的媒体服务器不存在。",
  "-20110087": "项目ID筛选条件不存在。",
  "-20110088": "数据格式不正确。",
  "-20110089": "父模块不存在。",
  "-20110090": "子模块不存在。",
  "-20110091": "固定模块不允许删除。",
  "-20110092": "不允许删除以下实体模块资产、关卡、集数、序列、镜头。",
  "-20110093": "模块存在实体数据，无法删除。",
  "-20110094": "OCR配置不存在。",
  "-20110095": "图片不存在。",
  "-20110096": "OCR营业执照文件解析失败。",
  "-20110097": "OCR身份证文件解析失败。",
  "-20110098": "OCR当前请求方法不支持。",
  "-20110099": "添加更新系统配置失败。",
  "-20110100": "未填写甲方负责人。",
  "-20110101": "订单不存在。",
  "-20110102": "订单当前状态不能执行此操作。",
  "-20110103": "用户信息不存在。",
  "-20110104": "角色信息不存在。",
  "-20110105": "已经是快速工作流模型。",
  "-20110106": "删除审核流程模板失败。",
  "-20110107": "创建日历失败。",
  "-20110108": "更新日历失败。",
  "-20110109": "删除日历失败。",
  "-20110110": "数据结构增加模块失败。",
  "-20110111": "修改模块数据失败。",
  "-20110112": "删除模块数据失败。",
  "-20110113": "创建数据结构数据失败。",
  "-20110114": "修改数据结构数据失败。",
  "-20110115": "删除数据结构数据失败。",
  "-20110116": "保存数据结构模块关联数据失败。",
  "-20110117": "新增数据结构模块关联数据失败。",
  "-20110118": "请按工序分类生成SKU。",
  "-20110119": "修改工序标准工时失败。",
  "-20110120": "工序分类不存在。",
  "-20110121": "只有根节点能够绑定实体参数。",
  "-20110122": "修改工序分类失败。",
  "-20110123": "项目不存在。",
  "-20110124": "初始化项目计划失败。",
  "-20110125": "工序ID请求参数不为空。",
  "-20110126": "请按工序生成SKU。",
  "-20110127": "创建标签失败。",
  "-20110128": "更新标签失败。",
  "-20110129": "删除标签失败。",
  "-20110130": "删除标签关联失败。",
  "-20110131": "添加标签关联失败。",
  "-20110132": "删除标签关联数据失败。",
  "-20110133": "增量更新标签失败。",
  "-20110134": "请求数据缺少项目ID参数。",
  "-20110135": "任务工作流配置不存在。",
  "-20110136": "无法找到匹配指定工序分类编码数据。",
  "-20110137": "请求数据缺少模块参数。",
  "-20110138": "任务不存在。",
  "-20110139": "不存在有效的审批工作流。",
  "-20110140": "不存在有效的审批工作流。",
  "-20110141": "用户信息中缺少用户角色数据。",
  "-20110142": "你还没有加入到任何一个部门。",
  "-20110143": "你的角色不在此工作流中。",
  "-20110144": "获取角色信息失败。",
  "-20110145": "没有足够的用户执行工序工作流检查。",
  "-20110146": "角色不存在。",
  "-20110147": "你还没有被分配角色。",
  "-20110148": "项目不存在。",
  "-20110149": "版本不存在。",
  "-20110150": "任务版本不存在。",
  "-20110151": "任务执行人信息不存在。",
  "-20110152": "任务不存在。",
  "-20110153": "前置任务未完成。",
  "-20110154": "当前项目没有项目模板。",
  "-20110155": "当项目模板任务统计工序未配置。",
  "-20110156": "工序配置不正确。",
  "-20110157": "修改项目模板配置失败。",
  "-20110158": "项目模板不存在。",
  "-20110159": "类不存在。",
  "-20110160": "Teamones平台未绑定租户信息。",
  "-20110161": "无效的法大大在线合同签署后回调地址。",
  "-20110162": "订单合同缺单。",
  "-20110163": "更新订单状态失败。",
  "-20110164": "更新源合同状态失败。",
  "-20110165": "缺少作废合同模板配置。",
  "-20110166": "没有匹配数据。",
  "-20110167": "无法获取计划锁，请稍后重试。",
  "-20110168": "获取计划锁已经超过最大尝试次数。",
  "-20110169": "查询法大大合同状态失败。",
  "-20110170": "当前用户已启动时间日志。",
  "-20110171": "启动时间日志失败。",
  "-20110172": "停止时间日志失败。",
  "-20110173": "创建用户资格证分类失败。",
  "-20110174": "更新用户资格证分类失败。",
  "-20110175": "删除用户资格证分类失败。",
  "-20110176": "属性选择不完整。",
  "-20110177": "更新用户系统配置失败。",
  "-20110178": "同步用户信息失败。",
  "-20110179": "订单不存在。",
  "-20110180": "订单合同不存在。",
  "-20110181": "增加结算记录失败。",
  "-20110182": "缺少订单结算审核配置。",
  "-20110183": "订单不存在。",
  "-20110184": "结算数据不存在。",
  "-20110185": "请求数据错误。",
  "-20110186": "保存发票数据失败。",
  "-20110187": "找不到匹配的发票记录。",
  "-20110188": "找不到匹配订单记录。",
  "-20110189": "修改发票信息失败。",
  "-20110190": "修改结算得打款时间失败。",
  "-20110191": "没有找到匹配数据。",
  "-20110192": "更新结算状态失败。",
  "-20110193": "订单发票状态无法应用。",
  "-20110194": "缺少客户发票申请。",
  "-20110195": "保存计划改动失败。",
  "-20110196": "新增计划失败。",
  "-20110197": "当前工序类别计划完成范围时间不足。",
  "-20110198": "实体未设置开始时间或结束时间。",
  "-20110199": "提交审批申请失败。",
  "-20110200": "修改版本状态失败。",
  "-20110201": "修改订单状态失败。",
  "-20110202": "用户当前角色不在当前审批节点里。",
  "-20110203": "没有足够的用户来执行工作流。",
  "-20110204": "获取用户信息失败。",
  "-20110205": "克隆任务数据失败。",
  "-20110206": "克隆项目数据失败。",
  "-20110207": "外包项目不允许重新分配。",
  "-20110208":
    "如果当前分类任务的子任务已经分配给组员并且不是默认任务状态，那么该任务不允许进行重新分配。",
  "-20110209": "实体不存在。",
  "-20110210": "工序流配置不存在。",
  "-20110211": "工序不存在。",
  "-20110212": "修改任务信息失败。",
  "-20110213": "状态不是默认的状态值。",
  "-20110214": "不是工序任务。",
  "-20110215": "设置任务执行人失败。",
  "-20110216": "任务实体不存在。",
  "-20110217": "任务的开始时间和结束时间 必须在项目的工序分类的计划区间内。",
  "-20110218": "没有匹配的实体数据。",
  "-20110219": "任务计划时间必须在指定的范围内。",
  "-20110220": "添加版本失败。",
  "-20110221": "版本关联的工序检查点失败。",
  "-20110222": "缺少任务审阅进度状态。",
  "-20110223": "缺少相关订单数据。",
  "-20110224": "任务订单缺少甲方负责人。",
  "-20110225": "无法查到甲方负责人信息。",
  "-20110226": "外包任务缺少审阅工作流数据。",
  "-20110227": "源任务缺少集数据。",
  "-20110228": "缺少导演角色。",
  "-20110229": "租户中缺少导演角色。",
  "-20110230": "关卡相关资产不存在。",
  "-20110231": "法大大自动签署合同失败。",
  "-20110232": "无法找到订单结算数据。",
  "-20110233": "登陆失败。",
  "-20110234": "没有角色数据。",
  "-20110235": "没有租户数据。",
  "-20110236": "媒体服务器不存在。",
  "-20110237": "媒体服务器不可用。",
  "-20110238": "模块添加失败。",
  "-20110239": "缺少订单合同模板配置。",
  "-20110240": "删除数据结构同时删除关联数据结构失败。",
  "-20110241": "保存当前字段配置修改失败。",
  "-20110242": "错误的header头参数。",
  "-20110243": "模块参数不存在。",
  "-20110244": "请求参数错误。",
  "-20110245": "未找到属性。",
  "-20110246": "解密失败。",
  "-20110247": "新增实体失败",
  "-20110248": "剧集分配添加失败",
  "-20110249": "剧集分配更新失败",
  "-20110250": "属性添加失败",
  "-20110251": "工序分类关联添加失败",
  "-20110252": "属性值添加失败",
  "-20110253": "格式化事件日志失败",
  "-20110254": "工序sku生成失败",
  "-20110255": "缺少消息模板配置",
  "-20110256": "任务返修单不存在",
  "-20110257": "缺少任务默认开始状态配置",
  "-20110258": "错误的审核点信息",
  "-20110259": "数据已经存在",
  "-20110260": "数据不合法",
  "-20110261": "没有修改任务数据",
  "-20110262": "还有历史版本未审核",
  "-20110263": "版本文件正在上传/因为错误锁定中,请稍后重试",
  "-20110264": "缺少初始集",
  "-20110265": "缺少集",
  "-20110266": "缺少剧集分配配置",
  "-20110267": "上一个版本审核还未结束",
  "-20110268": "缺少状态",
  "-20110269": "不支持的操作",
  "-20110270": "镜头序列名称不能为空",
  "-20110271": "未知的模块",
  "-20110272": "序列已经存在",
  "-20110273": "供应商不存在",
  "-20110274": "业务模式配置错误",
  "-20110275": "发布正在进行中",
  "-20110276": "计划组不存在",
  "-20110277": "资产计划未设置",
  "-20110278": "合并分类配置缺失",
  "-20110279": "资产计划时间不能早于概念计划时间",
  "-20110280": "概念计划时间不能晚于资产计划时间",
  "-20110281": "集计划未设置",
  "-20110282": "合并模块配置缺失",
  "-20110283": "镜头计划未设置",
  "-20110284": "缺少配置",
  "-20110285": "没有匹配的供应商",
  "-20110286": "当前计划时间不能超出父级计划时间",
  "-20110287": "计划不存在",
  "-20110288": "目录模板不存在",
  "-20110289": "目录模板类型不存在",
  "-20110290": "模板不存在",
  "-20110291": "项目模板没有选择",
  "-20110292": "两分钟内只能刷新一次",
  "-20110293": "任务统计数据正在生成",
  "-20110294": "播放列表不存在",
  "-20110295": "字段不存在",
  "-20110296": "审核申请不存在",
  "-20110297": "服务不可用",
  "-20110298": "上一个播放列表正在审核",
  "-20110299": "任务还在审核中",
  "-20110300": "缺少部门信息",
  "-20110301": "审核参数错误",
  "-20110302": "创建闹钟参数错误",
  "-20110303": "任务存在不同审核节点",
  "-20110304": "状态不存在",
  "-20110305": "任务数量超过限制",
  "-20110306": "闹钟时间不能超过结束计划结束以及计划开始时间",
  "-20110307": "创建闹钟数量超过限制",
  "-20110308": "制片不存在",
  "-20110309": "任务还有工单未关闭",
  "-20110310": "任务工单不存在",
  "-20110311": "任务工单已处理",
  "-20110312": "审核申请节点不存在",
  "-20110313": "缺少反馈信息",
  "-20110314": "任务数量超过限制50",
  "-20110315": "缺少工单取消标签配置",
  "-20110316": "审核已经结束",
  "-20110317": "任务审核点配置不存在",
  "-20110318": "任务已经开始",
  "-20110319": "当前部门没有组长，无法分配",
  // doc
  "-20410001": "创建文章分类失败。",
  "-20410002": "查询文章分类数据失败。",
  "-20410003": "删除项目失败。",
  "-20410004": "查询项目数据失败。",
  "-20410005": "查询子项目数据失败。",
  "-20410006": "删除文章失败。",
  "-20410007": "查询文章数据失败。",
};

export default en;
