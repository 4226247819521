import { homeState } from "@/store";

import { App } from "vue";

import { upload } from "@/api/common";

import mime from "mime";

/**
 *
 * @param param 媒体对象数据
 * @returns 拼接的媒体url
 * @description 统一获取传入媒体对象的url
 */

export const getMediaUrl = (
  param: Global.MediaData
): { url: string; preview: boolean } => {
  const httpUrl = homeState.mediaServer?.request_url;
  let url = "";
  let preview = false;
  if (param.type === "image") {
    url =
      httpUrl + "/" + param.path + param.md5_name + "_" + param.size + "." + param.ext;
  } else if (param.type) {
    url = httpUrl + "/" + param.path + param.folder + "." + param.ext;
  }

  if (param.type === "image" || param.type === "video") {
    preview = true;
  }

  return {
    url,
    preview,
  };
};

const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
/**
 *
 * @param val hex（#0c0c0c） 色值
 * @returns rgb（12, 12, 12） 色值
 * @description hex 转 rgb
 */
export const colorRgb = (val: string): string => {
  let sColor = val.toLowerCase();
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = "#";
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    const sColorChange = [];
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
    }
    return sColorChange.join(",");
  } else {
    return sColor;
  }
};

/**
 *
 * @param val rgb（12, 12, 12） 色值
 * @returns hex（#0c0c0c） 色值
 * @description rgb 转 hex
 */
export const colorHex = (val: string): string => {
  const that = val;
  if (/^(rgb|RGB)/.test(that)) {
    const aColor = that.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
    let strHex = "#";
    for (let i = 0; i < aColor.length; i++) {
      let hex = Number(aColor[i]).toString(16);
      if (hex === "0") {
        hex += hex;
      }
      strHex += hex;
    }
    if (strHex.length !== 7) {
      strHex = that;
    }
    return strHex;
  } else if (reg.test(that)) {
    const aNum = that.replace(/#/, "").split("");
    if (aNum.length === 6) {
      return that;
    } else if (aNum.length === 3) {
      let numHex = "#";
      for (let i = 0; i < aNum.length; i += 1) {
        numHex += aNum[i] + aNum[i];
      }
      return numHex;
    }
  }
  return that;
};

/**
 *
 * @param date 日期
 * @param fmt 日期格式 yyyy-MM-dd hh:mm:ss
 * @returns 格式化后的日期
 * @description 日期格式化
 */
export const formatDate = (date: string | number | Date, fmt?: string): string => {
  function padLeftZero(str: string) {
    return ("00" + str).substr(str.length);
  }

  if (!fmt) {
    fmt = "yyyy-MM-dd hh:mm:ss";
  }

  if (typeof date === "string" && !isNaN(Number(date))) {
    date = Number(date);
  }

  if (!date) {
    return "-";
  }

  if (typeof date === "number") {
    date = Math.round(date);
    if (date.toString().length === 10) {
      date = new Date(date * 1000);
    } else {
      date = new Date(date);
    }
  } else if (typeof date === "string") {
    date = date.replace("年", "-");
    date = date.replace("月", "-");
    date = date.replace("日", "");
    date = new Date(date);
  }

  if (isNaN(date.valueOf())) {
    return "-";
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  const o: Global.UnknownObj = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + "";
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
};

/**
 *
 * @param code 需要转换的code
 * @returns 转换后的name
 * @description 将 传入的code 值转换为对应的字符
 */
export const getCodeName = (code: string): string => {
  let word = "";
  switch (code) {
    case "super_admin":
      word = "超级管理员";
      break;
    case "admin":
      word = "管理员";
      break;
    case "editor":
      word = "编辑人员";
      break;
    case "viewer":
      word = "普通人员";
      break;
  }

  return word;
};
/**
 *
 * @param enumVal 枚举对象
 * @param val 值
 * @returns 值是否在枚举对象中
 * @description 检索值是否在枚举对象中
 */
export const getEnumHas = (enumVal: unknown, val: unknown): boolean => {
  let judge = false;
  if (enumVal instanceof Object) {
    judge = Object.values(enumVal).includes(val);
  }
  return judge;
};

/**
 *
 * @param time 传入的时间
 * @returns 返回距离现在的天数 返回 true 为传入时间不可用
 * @description 获取传入时间到现在的天数
 */
export const getNowDay = (time: string | number): number | true => {
  const timestamp = new Date(formatDate(time, "yyyy-MM-dd")).valueOf();

  if (isNaN(timestamp)) {
    return true;
  }
  const nowTimestamp = new Date(formatDate(Date.now(), "yyyy-MM-dd")).valueOf();

  const unit = 3600 * 24 * 1000;

  return (nowTimestamp - timestamp) / unit;
};

export const uploadFile = (
  files: Array<File> | File
): Promise<Array<Global.MediaData>> => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    if (files instanceof File) {
      files = [files];
    }

    files.forEach((el) => {
      data.append("Filedata", el);
    });

    upload("", homeState.mediaServer?.upload_url, data, {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + homeState.token,
    })
      .then((res) => {
        if (res && res.code === 0 && res.data && res.data.length) {
          resolve(res.data);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
};

// 将base64转换为文件
export const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(",");
  const mimeType = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${mime.getExtension(mimeType)}`, {
    type: mimeType,
  });
};

export const setMethod = (app: App<Element>): void => {
  app.config.globalProperties.$formatDate = formatDate;
  app.config.globalProperties.$getCodeName = getCodeName;
  app.config.globalProperties.$getEnumHas = getEnumHas;
};
