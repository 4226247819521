import axios, { AxiosRequestHeaders } from "axios";

/**
 * 上传通用请求
 * @param {}} url
 * @param {*} VUE_APP_HTTP
 * @param {*} data
 */
export function upload(
  url: string,
  baseURL = process.env.VUE_APP_HTTP,
  data: FormData,
  headers: AxiosRequestHeaders
): Promise<Global.ResData<Array<Global.MediaData>>> {
  const conf = {
    headers: headers,
    baseURL,
  };

  return new Promise((resolve, reject) => {
    axios.post(url, data, conf).then(
      (res) => {
        if (res.data && res.data.code === 0) {
          res.data.data = res.data.data || [];
          res.data.data.map((item: Global.MediaData) => {
            const word = item.type === "image" ? "/static/images" : "/static/video";
            item.thumb
              ? (item.thumb = word + item.thumb)
              : (item.thumb =
                  "/" + item.path + item.md5_name + "_" + item.size + "." + item.ext);
          });

          resolve(res.data);
        } else {
          resolve(res.data);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}
