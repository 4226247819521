import { CardEntry, PluginEntry, PluginOptions, sanitizeUrl } from "@aomao/engine";
//引入插件 begin
import Redo from "@aomao/plugin-redo";
import Undo from "@aomao/plugin-undo";
import Bold from "@aomao/plugin-bold";
import Code from "@aomao/plugin-code";
import Backcolor from "@aomao/plugin-backcolor";
import Fontcolor from "@aomao/plugin-fontcolor";
import Fontsize from "@aomao/plugin-fontsize";
import Italic from "@aomao/plugin-italic";
import Underline from "@aomao/plugin-underline";
import Hr, { HrComponent } from "@aomao/plugin-hr";
import Tasklist, { CheckboxComponent } from "@aomao/plugin-tasklist";
import Orderedlist from "@aomao/plugin-orderedlist";
import Unorderedlist from "@aomao/plugin-unorderedlist";
import Indent from "@aomao/plugin-indent";
import Heading from "@aomao/plugin-heading";
import Strikethrough from "@aomao/plugin-strikethrough";
import Sub from "@aomao/plugin-sub";
import Sup from "@aomao/plugin-sup";
import Alignment from "@aomao/plugin-alignment";
import Mark from "@aomao/plugin-mark";
import Quote from "@aomao/plugin-quote";
import PaintFormat from "@aomao/plugin-paintformat";
import RemoveFormat from "@aomao/plugin-removeformat";
import SelectAll from "@aomao/plugin-selectall";
import Link from "@aomao/plugin-link-vue";
import Codeblock, { CodeBlockComponent } from "@aomao/plugin-codeblock-vue";
import Image, { ImageComponent, ImageUploader } from "@aomao/plugin-image";
import Table, { TableComponent } from "@aomao/plugin-table";
import File, { FileComponent, FileUploader } from "@aomao/plugin-file";
import Video, { VideoComponent, VideoUploader } from "@aomao/plugin-video";
import Math, { MathComponent } from "@aomao/plugin-math";
import Fontfamily from "@aomao/plugin-fontfamily";
import Status, { StatusComponent } from "@aomao/plugin-status";
import LineHeight from "@aomao/plugin-line-height";

import fileType, { AcceptType } from "@/utils/fileType";

import lodash from "lodash";

// import Mention, { MentionComponent } from "@aomao/plugin-mention";
// import MarkRange from "@aomao/plugin-mark-range";
import {
  fontFamilyDefaultData,
  ToolbarComponent,
  ToolbarPlugin,
} from "@aomao/toolbar-vue";

import { homeState } from "@/store";

import { getMediaUrl } from "@/utils";

class FileComponentCopy extends FileComponent {
  downloadFile = () => {
    const value = this.getValue();
    if (!value?.download) return;
    const { download } = value;

    const downloadUrl = sanitizeUrl(this.onBeforeRender("download", download));

    if (window.opener) {
      window.opener.postMessage({
        type: "download",
        data: downloadUrl,
      });
    } else {
      window.open(downloadUrl);
    }
  };
  previewFile = () => {
    const value = this.getValue();
    if (!value?.preview) return;
    const { preview, name } = value;

    const previewUrl = sanitizeUrl(this.onBeforeRender("preview", preview));

    if (window.opener) {
      let type = "";
      for (const key in fileType) {
        const element = fileType[key];

        if (element.find((el) => name.includes(el))) {
          type = key;
        }
      }

      window.opener.postMessage({
        type: "preview",
        data: {
          src: previewUrl,
          type,
        },
      });
    } else {
      window.open(previewUrl);
    }
  };
}

const parse = (res: Global.ResData<Array<Global.MediaData>>, isOther?: boolean) => {
  console.log("csacascascas");

  if (res.code === 0 && res.data) {
    const resultData = getMediaUrl(res.data[0]);

    let data: Global.UnknownObj<string> | string;

    if (isOther === true) {
      data = {
        download: resultData.url,
        url: resultData.url,
      };
      resultData.preview && (data.preview = resultData.url);
    } else {
      data = resultData.url;
    }

    return {
      result: true,
      data,
    };
  } else {
    return {
      result: false,
    };
  }
};

const getConfig = (acceptType: AcceptType | "*") => {
  console.log(acceptType);

  let accept = "";
  if (acceptType === "*") {
    accept = lodash.flattenDeep(Object.values(fileType)).join(", ");
  } else {
    accept = fileType[acceptType].join(", ");
  }

  return {
    action: homeState.mediaServer?.upload_url,
    name: "Filedata",
    accept,
    limitSize: 1024 * 1024 * 20,
    headers: {
      Authorization: "Bearer " + homeState.token,
    },
  };
};

const getPlugins = (): Array<PluginEntry> => {
  const plugins: Array<PluginEntry> = [
    Redo,
    Undo,
    Bold,
    Code,
    Backcolor,
    Fontcolor,
    Fontsize,
    Italic,
    Underline,
    Hr,
    Tasklist,
    Orderedlist,
    Unorderedlist,
    Indent,
    Heading,
    Strikethrough,
    Sub,
    Sup,
    Alignment,
    Mark,
    Quote,
    PaintFormat,
    RemoveFormat,
    SelectAll,
    Link,
    Codeblock,
    Image,
    ImageUploader,
    // MarkRange,
    Table,
    File,
    FileUploader,
    Video,
    VideoUploader,
    Math,
    ToolbarPlugin,
    Fontfamily,
    Status,
    LineHeight,
    // Mention,
  ];

  return plugins;
};

const getCards = (): Array<CardEntry> => {
  const cards: Array<CardEntry> = [
    HrComponent,
    CheckboxComponent,
    CodeBlockComponent,
    ImageComponent,
    TableComponent,
    FileComponentCopy,
    VideoComponent,
    MathComponent,
    ToolbarComponent,
    StatusComponent,
    // MentionComponent,
  ];
  return cards;
};

type Res = {
  svg: unknown;
  success: unknown;
};

const getPluginConfig = (): { [key: string]: PluginOptions } => {
  const pluginConfig: { [key: string]: PluginOptions } = {
    [Italic.pluginName]: {
      // 默认为 _ 下划线，这里修改为单个 * 号
      markdown: "*",
    },
    [Image.pluginName]: {
      onBeforeRender: (status: string, url: string) => {
        if (url.startsWith("data:image/")) return url;
        return url;
      },
    },

    [ImageUploader.pluginName]: {
      file: getConfig(AcceptType.image),
      remote: getConfig(AcceptType.image),
      parse,
      isRemote: () => false,
    },
    [FileUploader.pluginName]: {
      ...getConfig("*"),
      parse: (val: Global.ResData<Array<Global.MediaData>>) => {
        return parse(val, true);
      },
    },
    [VideoUploader.pluginName]: {
      ...getConfig(AcceptType.video),
      parse,
    },
    [Video.pluginName]: {
      onBeforeRender: (status: string, url: string) => {
        return url;
      },
    },
    [Math.pluginName]: {
      action: `https://g.aomao.com/latex`,
      parse: (res: Res) => {
        if (res.success) return { result: true, data: res.svg };
        return { result: false };
      },
    },
    // [Mention.pluginName]: {
    //   action: `${homeState.requestUrl}/user/search`,
    //   onLoading: (root: NodeInterface) => {
    //     const vm = createApp(Loading);
    //     const element = root.get<HTMLElement>();
    //     element && vm.mount(element);
    //   },
    //   onEmpty: (root: NodeInterface) => {
    //     const vm = createApp(Empty);
    //     const element = root.get<HTMLElement>();
    //     element && vm.mount(element);
    //   },
    //   onClick: (root: NodeInterface, { key, name }: { key: string; name: string }) => {
    //     console.log("mention click:", key, "-", name);
    //   },
    //   // onMouseEnter: (layout: NodeInterface, { name }: { key: string; name: string }) => {
    //   //   const vm = createApp(MentionPopover, {
    //   //     name,
    //   //   });
    //   //   vm.mount(layout.get<HTMLElement>()!);
    //   // },
    // },
    [Fontsize.pluginName]: {
      //配置粘贴后需要过滤的字体大小
      filter: (fontSize: string) => {
        return (
          [
            "12px",
            "13px",
            "14px",
            "15px",
            "16px",
            "19px",
            "22px",
            "24px",
            "29px",
            "32px",
            "40px",
            "48px",
          ].indexOf(fontSize) > -1
        );
      },
    },
    [Fontfamily.pluginName]: {
      //配置粘贴后需要过滤的字体
      filter: (fontfamily: string) => {
        const item = fontFamilyDefaultData.find((item) =>
          fontfamily
            .split(",")
            .some(
              (name) =>
                item.value.toLowerCase().indexOf(name.replace(/"/, "").toLowerCase()) > -1
            )
        );
        return item ? item.value : false;
      },
    },
    [LineHeight.pluginName]: {
      //配置粘贴后需要过滤的行高
      filter: (lineHeight: string) => {
        if (lineHeight === "14px") return "1";
        if (lineHeight === "16px") return "1.15";
        if (lineHeight === "21px") return "1.5";
        if (lineHeight === "28px") return "2";
        if (lineHeight === "35px") return "2.5";
        if (lineHeight === "42px") return "3";
        // 不满足条件就移除掉
        return ["1", "1.15", "1.5", "2", "2.5", "3"].indexOf(lineHeight) > -1;
      },
    },
  };
  return pluginConfig;
};

export { getPlugins, getCards, getPluginConfig };
