import { reactive } from "vue";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

import md5 from "js-md5";

import {
  getMediaServer as _getMediaServer,
  getUserInfo as _getUserInfo,
  TokenInfo,
} from "@/api/im";

import router from "@/router";

import {
  qrcodeLogin as _qrcodeLogin,
  login as _login,
  logout as _logout,
} from "@/api/im";

// 拿取设备码
const getDeviceUniqueCode = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (homeState.deviceUniqueCode) {
      resolve(homeState.deviceUniqueCode);
      return;
    }

    FingerprintJS.load()
      .then((fp) => {
        fp.get()
          .then((result) => {
            const visitorId = result.visitorId;
            // 指纹
            const deviceUniqueCode = md5(visitorId);
            if (deviceUniqueCode) {
              homeState.deviceUniqueCode = deviceUniqueCode;
              sessionStorage.setItem("deviceUniqueCode", deviceUniqueCode);
              resolve(deviceUniqueCode);
            } else {
              reject("Failed to generate device unique code.");
            }
          })
          .catch(() => {
            reject("Failed to generate device unique code.");
          });
      })
      .catch(() => {
        reject("Failed to generate device unique code.");
      });
  });
};

// 获取媒体信息
const getMediaServer = () => {
  _getMediaServer().then((res) => {
    if (res && res.code === 0 && res.data) {
      homeState.mediaServer = res.data;
      sessionStorage.setItem("mediaServer", JSON.stringify(res.data));
    }
  });
};
// 获取用户相关信息
const getUserInfo = () => {
  _getUserInfo().then((res) => {
    if (res && res.code === 0 && res.data) {
      homeState.userInfo = res.data;
      sessionStorage.setItem("userInfo", JSON.stringify(res.data));
    }
  });
};
// 重置数据
const resetData = (): void => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("isFrame");
  sessionStorage.removeItem("userInfo");
  sessionStorage.removeItem("mediaServer");
  Object.assign(homeState, init(), { deviceUniqueCode: homeState.deviceUniqueCode });
};
// code 码登录
const qrcodeLogin = (qr_code_id: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const data = {
      qr_code_id,
    };

    _qrcodeLogin(data)
      .then((res) => {
        if (res && res.code === 0 && res.data) {
          const data = res.data;
          homeState.token = data.access_token;
          homeState.refreshToken = data.refresh_token;
          homeState.isFrame = "yes";
          sessionStorage.setItem("token", data.access_token);
          sessionStorage.setItem("refreshToken", data.refresh_token);
          sessionStorage.setItem("isFrame", "yes");

          window.requestIdleCallback(getMediaServer);
          window.requestIdleCallback(getUserInfo);
          resolve(data);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
};
// 登录
const login = (data: unknown): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    _login(data)
      .then((res) => {
        if (res && res.code === 0 && res.data) {
          const data = res.data;
          homeState.token = data.access_token;
          homeState.refreshToken = data.refresh_token;

          sessionStorage.setItem("token", data.access_token);
          sessionStorage.setItem("refreshToken", data.refresh_token);
          window.requestIdleCallback(getMediaServer);
          window.requestIdleCallback(getUserInfo);
          resolve(data);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
};
// 退出登录
const logout = (isRedirect?: boolean): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    resetData();

    _logout()
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });

    let str = "";

    if (isRedirect) {
      const route = router.currentRoute.value;
      const query = route.query as Global.UnknownObj<string>;

      str = `?redirect=${route.name as string}`;

      for (const key in query) {
        str += `&${key}=${query[key]}`;
      }
    }
    // router.replace(`/login${str}`);
    location.href = `/login${str}`;
  });
};
// 刷新token
const refreshToken = (): Promise<TokenInfo> => {
  return new Promise((resolve, reject) => {
    const data = { grant_type: "refresh_token", refresh_token: homeState.refreshToken };

    _login(data)
      .then((res) => {
        if (res && res.code === 0 && res.data) {
          const data = res.data;
          homeState.token = data.access_token;
          homeState.refreshToken = data.refresh_token;
          sessionStorage.setItem("token", data.access_token);
          sessionStorage.setItem("refreshToken", data.refresh_token);
          resolve(data);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
};

// 初始化数据
const init = (): Global.StateData => {
  const mediaServerStr = sessionStorage.getItem("mediaServer");
  const userInfoStr = sessionStorage.getItem("userInfo");

  return {
    mediaServer: mediaServerStr ? JSON.parse(mediaServerStr) : null,
    token: sessionStorage.getItem("token") || "",
    refreshToken: sessionStorage.getItem("refreshToken") || "",
    deviceUniqueCode: sessionStorage.getItem("deviceUniqueCode") || "",
    userInfo: userInfoStr ? JSON.parse(userInfoStr) : null,
    only: sessionStorage.getItem("only") === "yes" ? "yes" : "no",
    isFrame: sessionStorage.getItem("isFrame") === "yes" ? "yes" : "no",
  };
};

const homeState = reactive<Global.StateData>(init());

getDeviceUniqueCode();

export {
  homeState,
  qrcodeLogin,
  login,
  refreshToken,
  resetData,
  logout,
  getDeviceUniqueCode,
};
