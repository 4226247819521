import router from "@/router";

import { homeState, qrcodeLogin, getDeviceUniqueCode } from "@/store";
import { message } from "ant-design-vue";

const whiteList: Array<unknown> = ["404", "editor-full", "login", "demo"];

router.beforeEach(async (to, from, next) => {
  document.title = "TEONES 知识库";
  const qr_code_id = to.query.qr_code_id as string;

  const device_unique_code = to.query.device_unique_code as string;
  const only = to.query.only === "yes" ? "yes" : "no";

  if (only === "yes") {
    homeState.only = only;
    sessionStorage.setItem("only", "yes");
  }

  if (!device_unique_code && !homeState.deviceUniqueCode) {
    let judge = false;

    try {
      await getDeviceUniqueCode();
    } catch (error) {
      judge = true;
    }

    if (judge) {
      message.error("获取不到唯一识别码，请联系管理员");
      next("/404");
      return;
    }
  }
  if (qr_code_id || device_unique_code) {
    const urlObj = new URL(location.href);

    urlObj.searchParams.delete("qr_code_id");
    urlObj.searchParams.delete("device_unique_code");

    const href = urlObj.href.split(urlObj.origin)[1];

    if (device_unique_code) {
      homeState.deviceUniqueCode = device_unique_code;
    }

    if (qr_code_id) {
      try {
        await qrcodeLogin(qr_code_id);
      } catch (error) {
        console.log(error);
      }

      next(href);
    } else {
      next(href);
    }
  } else if (whiteList.includes(to.name)) {
    next();
  } else if (!homeState.token) {
    next("/login");
  } else {
    next();
  }
});
