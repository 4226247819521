enum AcceptType {
  image = "image",
  video = "video",
  audio = "audio",
  file = "FileType",
}

const mediaType: Global.UnknownObj<Array<string>> = {
  [AcceptType.image]: ["gif", "jpeg", "jpg", "bmp", "png"],
  [AcceptType.video]: ["mov", "mp4", "avi", "wmv", "flv"],
  [AcceptType.audio]: ["mp3"],
  [AcceptType.file]: [
    "pdf",
    "ppt",
    "pptx",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "txt",
    "zip",
    "rar",
    "7z",
  ],
};

export { AcceptType };

export default mediaType;
