import { ref } from "vue";

// import { engine, setContent } from "@/hooks/doc-editor";

import { set, getVal, reset, docEmitter } from "@/views/doc";

export enum ChannelType {
  init = "init-port",
  set = "set",
  save = "save",
  clear = "clear",
}

export const port = ref<MessagePort | null>(null);

docEmitter.on("ctrlS", () => {
  getVal().then((res) => {
    port.value?.postMessage({
      type: ChannelType.save,
      data: res,
    });
  });
});

window.addEventListener("message", onMessage);

function onMessage(e: MessageEvent) {
  if (e.data instanceof Object) {
    if (e.data.type === ChannelType.init) {
      port.value = e.ports[0];
    } else if (e.data.type === ChannelType.set) {
      set(e.data.data);
    } else if (e.data.type === ChannelType.save) {
      getVal().then((res) => {
        port.value?.postMessage({
          type: ChannelType.save,
          data: res,
        });
      });
    } else if (e.data.type === ChannelType.clear) {
      reset();
    }
  }
}
