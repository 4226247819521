import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("@/views/demo.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/editor-full",
    name: "editor-full",
    component: () => import("@/views/doc/index.vue"),
  },
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layouts/BasicLayout.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "editor",
        name: "editor",
        component: () => import("@/views/editor/index.vue"),
      },
    ],
  },
  // { path: "/:pathMatch(.*)*", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
