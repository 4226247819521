import { RoleType } from "./person";

export enum DocType {
  init = 0,
  word = 1,
  excel = 2,
  catalog = 3,
  draw = 4,
}

export interface DocInfo {
  catalog?: Array<DocInfo>;
  book_type_id?: number;
  catalog_id?: number;
  comments?: string;
  content?: string;
  created?: string;
  created_by?: string | number;
  file_type?: DocType;
  id?: number;
  is_change?: number;
  is_del?: number;
  item_id?: number;
  last_update_time?: string;
  level?: number;
  name?: string;
  number?: number;
  param?: Global.UnknownObj;
  parent_id?: number;
  type?: string;
  role?: RoleType;
  [propsName: string]: unknown;
}
