import axios, { Canceler } from "axios";

import { homeState, logout } from "@/store/index";

import { message } from "ant-design-vue";

import router from "@/router";

import { doRequest } from "./doRequest";

import { getMsg } from "@/i18n";

type Cancel = {
  umet: string;
  cancel: Canceler;
};

const sources: Array<Cancel> = []; // 定义数组用于存储每个ajax请求的取消函数及对应标识
// 定义取消操作
const removeSource = (obj: Cancel) => {
  const index = sources.findIndex((item) => item.umet === obj.umet);
  if (index !== -1) {
    sources[index].cancel("cancel");
    sources.splice(index, 1);
  }
  sources.push(obj);
};
const CancelToken = axios.CancelToken;

const http = axios.create({
  baseURL: process.env.VUE_APP_HTTP,
  timeout: 50000,
  validateStatus: function (status) {
    return status < 500; // 状态码在大于或等于500时才会 reject
  },
});

http.interceptors.request.use(
  (config) => {
    if (homeState.token && config.headers) {
      config.headers.Authorization = "Bearer " + homeState.token;
    }
    if (!config.data) {
      config.data = {};
    }
    config.data.device_unique_code = homeState.deviceUniqueCode;
    config.data.client_id = "818aeb54172a95a8073dbc56dd8ec675";
    if (!config.data._isNotCancel) {
      config.cancelToken = new CancelToken((c) => {
        // 将取消函数存起来
        removeSource({ umet: config.method + "&" + config.url, cancel: c });
      });
    }
    delete config.data._isNotCancel;

    return config;
  },
  (error) => {
    return error;
  }
);

http.interceptors.response.use(
  async (response) => {
    const res = response.data;

    if (response.status !== 200 || res.code !== 0) {
      if (
        (response.status === 401 || res.code === -20310002) &&
        router.currentRoute.value.name !== "login"
      ) {
        if (homeState.refreshToken) {
          const res = await doRequest(response);
          return res;
        } else {
          logout();
          message.error(`登录信息已过期，请重新登录。`);
        }
      } else if (
        res.code === -20310004 ||
        res.code === -20110235 ||
        res.code === -20110293 ||
        res.code === -20110316 ||
        res.code === -20410032
      ) {
        return res;
      } else if (response.status === 200) {
        message.error(`错误：${getMsg(res.code, res.msg)}`);
      } else {
        message.error(`未知错误：${getMsg(response.status)}`);
      }

      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    if (error.message.indexOf("timeout") > -1) {
      message.error(getMsg("504"));
    } else if (error.message === "Network Error") {
      message.error(getMsg("400"));
    } else if (error.message !== "cancel") {
      message.error(error.message);
    }
    return error;
  }
);

export { http };
