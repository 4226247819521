import { App, defineAsyncComponent } from "vue";

const ComSvg = defineAsyncComponent(() => import("./com-svg.vue"));
const ComAvatar = defineAsyncComponent(() => import("./com-avatar.vue"));
const ComImg = defineAsyncComponent(() => import("./com-img.vue"));
const ComInput = defineAsyncComponent(() => import("./com-input.vue"));

const setCom = function (app: App<Element>): void {
  app.component("com-svg", ComSvg);
  app.component("com-avatar", ComAvatar);
  app.component("com-img", ComImg);
  app.component("com-input", ComInput);
};
export default setCom;
