import { http } from "../utils/service";

import { Member, RoleInfo } from "./doc";

export interface TokenInfo {
  refresh_token: string;
  access_token: string;
}
export interface Department {
  id: number;
  department_id?: number;
  name?: string;
  parent_id?: number;
  user_count?: number;
  type?: "department";
  department_name?: string;
  department_step_category_ids?: string;
  role_code?: string;
  role_id?: number;
  role_name?: string;
  tenant_id?: number;
  tenant_position_id?: number;

  [propName: string]: unknown;
}
/**
 *
 * @description 发送消息
 */
export function sendMsg(data: unknown): Promise<Global.ResData<unknown>> {
  return http({
    url: "/api_im/message/send_message",
    method: "post",
    data,
  });
}
/**
 *
 * @description 搜索相关联系人
 */
export function getDepartmentUser(
  data: unknown
): Promise<Global.ResData<Global.TotalType<Member>>> {
  return http({
    url: "/api_saas/tenant/select_department_user",
    method: "post",
    data,
  });
}
/**
 *
 * @description 获取组织架构
 */
export function getChildrenDepartmentUser(
  data: unknown
): Promise<Global.ResData<{ children?: Array<Department>; members?: Array<Member> }>> {
  return http({
    url: "/api_im/user/get_children_department_user",
    method: "post",
    data,
  });
}
/**
 *
 * @description 获取部门或人员 可用的人员id
 */
export function getInviteUser(data: unknown): Promise<Global.ResData<Array<number>>> {
  return http({
    url: "/api_im/chat_user/get_invite_user",
    method: "post",
    data,
  });
}
/**
 *
 * @description 获取媒体服务数据
 */
export function getMediaServer(
  data?: unknown
): Promise<Global.ResData<Global.MediaServer>> {
  return http({
    url: "/api_im/options/get_media_upload_server",
    method: "post",
    data,
  });
}

/**
 *
 * @description 获取当前用户的信息。 公司，部门，个人
 */
export function getUserInfo(data?: unknown): Promise<
  Global.ResData<{
    department_info?: Array<Department>;
    user?: RoleInfo;
    tenant?: {
      id: number;
      name: string;
      created_by: number;
    };
  }>
> {
  return http({
    url: "/api_saas/user/get_user_info",
    method: "post",
    data,
  });
}

/**
 *
 * @description code码登录
 */
export function qrcodeLogin(data?: unknown): Promise<Global.ResData<TokenInfo>> {
  return http({
    url: "/api_oauth/oauth/get_token_by_qr_code",
    method: "post",
    data,
  });
}
/**
 *
 * @description 登录
 */
export function login(data?: unknown): Promise<Global.ResData<TokenInfo>> {
  return http({
    url: "/api_oauth/oauth/get_token",
    method: "post",
    data,
  });
}

/**
 *
 * @description 退出登录
 */
export function logout(data?: unknown): Promise<Global.ResData<TokenInfo>> {
  return http({
    url: "/api_oauth/oauth/unset_token",
    method: "post",
    data,
  });
}
