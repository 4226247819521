import { refreshToken, logout } from "@/store";

import axios, { AxiosResponse } from "axios";

import { TokenInfo } from "@/api/im";
// 缓存
let subscribers: Array<(_: TokenInfo) => void> = [];
let isRefreshing = true;

function onAccessTokenFetched(data: TokenInfo) {
  subscribers.forEach((callback) => {
    callback(data);
  });
  subscribers = [];
}
function addSubscriber(callback: (_: TokenInfo) => void) {
  subscribers.push(callback);
}

async function doRequest(response: AxiosResponse): Promise<unknown> {
  // 取出所有刷新请求并重新请求
  const res = new Promise((resolve) => {
    addSubscriber(async (res: TokenInfo) => {
      const config = response.config;

      if (config.headers) {
        config.headers.Authorization = "Bearer " + res.access_token;
      } else {
        config.headers = {
          Authorization: "Bearer " + res.access_token,
        };
      }
      const r = await axios.request(config);
      resolve(r.data);
    });
  });

  // 缓存所有刷新请求，发送第一个请求
  if (isRefreshing) {
    isRefreshing = false;

    let tokenInfo: TokenInfo | null = null;
    try {
      tokenInfo = await refreshToken();
    } catch (error) {
      console.log("token 获取失败");
    }

    if (tokenInfo && tokenInfo.access_token) {
      // 执行缓存中的请求
      onAccessTokenFetched(tokenInfo);
      // 延迟几秒再将刷新token的开关放开，不然偶尔还是会重复提交刷新token的请求
      setTimeout(() => {
        isRefreshing = true;
      }, 3000);
    } else {
      logout();
    }
  }
  return res;
}

export { doRequest };
